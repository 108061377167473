import React, { Component } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Email_top from "../images/email_marketing/email_marketing_top.jpg"
import Process__Step_Sr1 from "../images/shopify/def_approach.png"
import Process__Step_Sr2 from "../images/shopify/best_aaproch.png"
import Process__Step_Sr3 from "../images/shopify/consulting.png"
import Newsletter_planning from "../images/email_marketing/newsletter.png"
import Automation from "../images/email_marketing/automation.png"
import {Helmet} from "react-helmet"

export default class Container extends Component {
  constructor(props) {
    super(props);
    this.state={
      url:''
    }
  }

  componentDidMount() {
    this.setState({
      url: document.location.href
    });
  }
  render() {
    return (
      <Layout>
        <Helmet>
          <title>
            Email Marketing & Automation for Certified B Corps & Purpose Driven Brands</title>
          <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              <link rel="canonical" href={this.state.url} />
              <meta name="description" content="Engage and inspire with CueForGood' email marketing. Our team crafts compelling email narratives for sustainable, vegan, and purpose-driven brands, ensuring meaningful interactions with your audience." />
              <meta property="og:site_name" content="CueForGood" />
              <meta property="fb:app_id" content="289086684439915" />
              <meta property="og:url" content={this.state.url} />
              <meta property="og:title" content="Email Marketing & Automation for Certified B Corps & Purpose Driven Brands" />
              <meta property="og:description" content="Engage and inspire with CueForGood' email marketing. Our team crafts compelling email narratives for sustainable, vegan, and purpose-driven brands, ensuring meaningful interactions with your audience." />
              <meta property="og:image" content="https://www.cueforgood.com/images/cb.jpg" />

              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:description" content="Engage and inspire with CueForGood' email marketing. Our team crafts compelling email narratives for sustainable, vegan, and purpose-driven brands, ensuring meaningful interactions with your audience." />
              <meta name="twitter:title" content="Email Marketing & Automation for Certified B Corps & Purpose Driven Brands" />
              <meta name="twitter:site" content="@CueForGood" />
              <meta name="twitter:image" content="https://www.cueforgood.com/images/cb.jpg" />
        </Helmet>
        <article className="static_page php-page email-marketing-page">
          <section className="ser_sec_row  creative_design_page ">
            <div className="container">

              <div className="ser_sec_row_lt wdt-40 pd_right_40">
                <h2 className="heading_main"> email marketing</h2>
                <div className="ser_sec_row_lt_text">
                  <p>
                    The attention span of the audiences is quite momentary nowadays, making re-engagement a necessity in the online world. When
                    customers feel safe with your brand and the products you offer, they feel more inclined to share their interests and preferences with you.
                  <br /><br />
                    With the expertise of our marketing team, we create
                    well-crafted targeted email marketing campaigns integrated with quality content that not only helps increase the loyalty of your
                    customers but also reinforces your brand’s value in the market, eventually leading to an increase in the revenue.
                  </p>
                </div>
              </div>
              <div className="ser_sec_row_rt wdt-60 pd_left_40 text-center">
                <img
                  src={Email_top}
                  alt="Email Marketing"
                  className="img-responsive"
                />
              </div>
              <div className="border-light"> </div>
            </div>
          </section>

          <section className=" our-process">
            <div className="container">
              <div className="main-heading text-center">
                <h2 className="heading_main">OUR PROCESS</h2>
              </div>
              <div className="process-steps">
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr1}
                        alt="Bridge the Gap"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>Bridge the Gap</h3>
                    <p>
                      Unlike other domains of marketing, email marketing follows a set of well-defined guidelines that our team follows to bridge the gap between your current marketing strategy and the pertinent practices that generate optimal results.
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr2}
                        alt="Optimal Use"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>Optimal Use</h3>
                    <p>
                      Checking off the Do’s & Don’ts of
                      generating subscriptions, our team
                      of experts assesses the sizeable
                      opportunities offered by your website to attract more subscribers through a targeted email marketing strategy, without being assertive.

                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr3}
                        alt="Reporting"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>Reporting</h3>
                    <p>
                      To target the correct audience with an emailer or newsletter, we use the data generated by most email service providers, which is then segmented on the basis of the
                      interests and preferences of your customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics setup-audit">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <h2 className="heading_main">
                    Setup
                  </h2>
                  <div className="ser_sec_row_lt_text setup-left">
                    <p>
                      At the core of your email marketing strategy lies the ability to
                      effectively interact with the customers through optimal integration of content that is delivered efficiently. Our team of adept
                      professionals streamlines these basic configurations in accordance with your email marketing strategy to give the most ideal results.
                     
                     
                    </p>
                    <Link to="/contact/" id="first-focus-element" className="btn main_cta focus-visible">
                      Get in touch
                  </Link>
                  </div>
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">
                    Audit
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Considering the dynamic nature of the online world, it is important to keep a tab on what’s new in the industry and the guidelines it follows. We dive deep into your site’s
                      configurations and identify any bottleneck concerns that may hinder the effectiveness of the email marketing
                      strategy.
                    </p>
                    <Link to="/contact/" className="btn main_cta focus-visible">
                      Get in touch
                  </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics bg-tab">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <figure>
                    <img
                      src={Newsletter_planning}
                      alt="Newsletter Planning"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">
                    Newsletter Planning
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      A successful marketing strategy captures the right audience at the right time. A newsletter marketing campaign will prove fruitful only if 
                      your customers are convinced by the legitimacy of your brand, its products, and the offers. We get our best minds from respective teams to work on the most optimal email marketing strategy that is complemented with crisp content and elegant design.
                    </p>
                  </div>
                  <Link to="/contact/" className="btn main_cta focus-visible">
                    Get in touch
                  </Link>
                </div>
              </div>
            </div>
          </section>



          <section className=" motion-graphics right-img border-section last_product ">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_left_20 right">
                  <figure>
                    <img
                      src={Automation}
                      alt="Automation Planning"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_right_40">
                  <h2 className="heading_main">Automation Planning</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Some newsletters do better than the others. So, how do you stick to the winning strategy with each new email marketing strategy? To avoid the hassle of recreating high-performance campaigns, our team of experts helps you strategize the more optimal strategy for automated email marketing that leads to an increase in revenue with minimal investment.
         
                    </p>
                  </div>
                  <Link to="/contact/" className="btn main_cta focus-visible">
                    Get in touch
                  </Link>
                </div>
              </div>
            </div>
          </section>

        </article>
      </Layout>
    )
  }
}
